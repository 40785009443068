import React from 'react';

import { Layout, SEO } from './../components';
import { Link, graphql, navigate } from 'gatsby';
import { useSelector } from 'react-redux';

import CommentSection from 'components/CommentSection/CommentSection';

/**
 * @param {{
 *    data: {
 *      brainteaser: {
 *        originalId: string
 *        description: string
 *        hint: string
 *        title: string
 *        forumLink?: string
 *        subscriptionOnly: boolean
 *        seo?: {
 *          title: string
 *          description: string
 *          image?: {
 *              url: string
 *          }
 *        }
 *        seoKeywords?: {
 *          keyword: string
 *        }[],
 *        schemaMarkup: string
 *      }
 *    }
 * }} param
 */
export default function Brainteaser({ data }) {
  const {
    originalId,
    description,
    hint,
    title,
    forumLink,
    seo,
    seoKeywords,
    schemaMarkup,
    subscriptionOnly,
  } = data.brainteaser;

  const user = useSelector((state) => state.user.data);
  const isSubscribed = user?.subscribed ?? false;

  React.useEffect(() => {
    if (subscriptionOnly && !isSubscribed) navigate('/brainteasers');
  }, [user]);

  const [hintVisible, setHintVisible] = React.useState(false);

  return (
    <Layout>
      <SEO
        description={seo && seo.description}
        image={seo && seo.image}
        title={(seo && seo.title) ?? `Brainteaser-${title}`}
        seoKeywords={seoKeywords}
        schema={schemaMarkup && JSON.parse(schemaMarkup)}
      />
      <div className="container content-section mb-2 pt-0 brainteaser-container">
        <Link className="brainteaser-breadcrumb" to="/brainteasers">
          Brainteasers
        </Link>
        <h1>{title}</h1>
        <div dangerouslySetInnerHTML={{ __html: description }} />
        {hint && (
          <button className="button ml-0 mr-0 mb-0.5" onClick={() => setHintVisible(!hintVisible)}>
            {hintVisible ? 'Hide' : 'Show'} hint
          </button>
        )}
        {hintVisible && <p className="brainteaser-hint">{hint}</p>}
        {forumLink && (
          <button className="button ml-0 mr-0" onClick={() => navigate(forumLink)}>
            Discuss on forum
          </button>
        )}
        <CommentSection postId={originalId} />
      </div>
    </Layout>
  );
}

const query = graphql`
  query BrainteaserQuery($id: String!) {
    brainteaser: datoCmsBrainteaser(originalId: { eq: $id }) {
      description
      hint
      originalId
      title
      forumLink
      subscriptionOnly
      seo {
        description
        image {
          url
        }
        title
      }
      seoKeywords {
        keyword
      }
      schemaMarkup
    }
  }
`;

export { query };
