import React from 'react';
import { useSelector } from 'react-redux';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

import { getCommentsForPost, createComment, replyToComment } from '@src/utils/mmc-api/api';

function timeAgo(date) {
  const currentTimestamp = Math.floor(Date.now() / 1000);
  const timestamp = Math.floor(date.getTime() / 1000);
  const secondsAgo = currentTimestamp - timestamp;

  if (secondsAgo < 60) {
    return `${secondsAgo} second${secondsAgo === 1 ? '' : 's'} ago`;
  } else if (secondsAgo < 3600) {
    const minutesAgo = Math.floor(secondsAgo / 60);
    return `${minutesAgo} minute${minutesAgo === 1 ? '' : 's'} ago`;
  } else if (secondsAgo < 86400) {
    const hoursAgo = Math.floor(secondsAgo / 3600);
    return `${hoursAgo} hour${hoursAgo === 1 ? '' : 's'} ago`;
  } else {
    const daysAgo = Math.floor(secondsAgo / 86400);
    return `${daysAgo} day${daysAgo === 1 ? '' : 's'} ago`;
  }
}

function Comment({ id, content, username, created_at, replies = [], postId }) {
  const [showReplies, setShowReplies] = React.useState(false);
  const [isReplying, setIsReplying] = React.useState(false);
  const [replyContent, setReplyContent] = React.useState('');

  const queryClient = useQueryClient();

  const { mutate, isLoading, isError } = useMutation({
    mutationFn: () => replyToComment({ content: replyContent, post_id: postId, comment_id: id }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['comments', postId] });
      setReplyContent('');
      setIsReplying(false);
    },
  });

  return (
    <>
      <div className="comment">
        <div className="comment-header p-1">
          <p className="mb-0">
            <span className="comment-name">{username}</span>
            <span className="comment-time">{timeAgo(new Date(created_at))}</span>
          </p>

          <p className="0.75 comment-time mb-0 cursor-pointer">
            <span onClick={() => setIsReplying(true)}>Reply</span>
            {replies.length > 0 && (
              <span className="ml-1" onClick={() => setShowReplies((prev) => !prev)}>
                {showReplies ? 'Hide' : 'Show'} replies
              </span>
            )}
          </p>
        </div>
        <div className="comment-content">
          <p className="mb-0">{content}</p>
          {isReplying && (
            <div>
              <textarea
                rows={10}
                value={replyContent}
                onChange={(e) => setReplyContent(e.target.value)}
                placeholder="Enter your reply here"
              ></textarea>
              <button
                className="button ml-0"
                onClick={() => {
                  if (!isLoading) mutate();
                }}
              >
                Reply
              </button>
            </div>
          )}
        </div>
      </div>
      {showReplies && (
        <div className="replies">
          {replies.map((reply, i) => (
            <div className={`pl-4 pb-1 ${i === replies.length - 1 ? 'last-reply' : 'reply'}`}>
              <div className="comment mb-0">
                <div className="comment-header p-1">
                  <p className="mb-0">
                    <span className="comment-name">{reply.username}</span>
                    <span className="comment-time">{timeAgo(new Date(reply.created_at))}</span>
                  </p>
                </div>
                <div className="comment-content">
                  <p className="mb-0">{reply.content}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
}

/**
 * @param {{
 *    postId: string
 * }} param
 * @returns
 */
function CommentSection({ postId }) {
  const user = useSelector((state) => state.user.data);
  const { data, isLoading } = useQuery(['comments', postId], () => getCommentsForPost(postId));

  if (isLoading) return <div>Loading comments</div>;
  return (
    <div>
      {data?.length ? (
        data.map((comment) => <Comment {...comment} postId={postId} />)
      ) : (
        <div>Be the first comment! </div>
      )}
    </div>
  );
}

function NewComment({ postId }) {
  const [content, setContent] = React.useState('');
  const queryClient = useQueryClient();

  const { mutate, isLoading, isError } = useMutation({
    mutationFn: () => createComment({ content, post_id: postId }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['comments', postId] });
      setContent('');
    },
  });

  return (
    <div className="new-comment-wrapper">
      <p className="new-comment-title">Join the discussion</p>
      <textarea
        rows={10}
        value={content}
        onChange={(e) => setContent(e.target.value)}
        placeholder="Enter your comment here"
      ></textarea>
      <button
        className="button ml-0"
        onClick={() => {
          if (!isLoading) mutate();
        }}
      >
        Comment
      </button>
    </div>
  );
}

export default function CommentSectionWrapper({ postId }) {
  return (
    <div className="mt-1">
      <CommentSection postId={postId} />
      <NewComment postId={postId} />
    </div>
  );
}
